import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import ManufacturersForm from "../../../components/forms/setup/manufacturers/ManufacturersForm";
import { SimpleNotificationType } from "../../../components/SimpleNotification";

export function ManufacturersViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [manufacturers, setManufacturers] = useState({ data: [] });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id");

  const getManufacturers = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`manufacturers`, payload);
    setLoading(false);
    if (callResult.success) {
      setManufacturers(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createManufacturer = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall("createManufacturer", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Manufacturer added",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getManufacturers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateManufacturer = async function (manufacturer, values, index) {
    let callResult = await makePutApiCall(
      `manufacturer/${manufacturer.id}`,
      values
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Manufacturer updated",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getManufacturers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddManufacturerSlideOver = () => {
    setSlideOver({
      childComponent: (
        <ManufacturersForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createManufacturer}
        />
      ),
      open: true,
    });
  };

  const viewManufacturerDetails = async function (manufacturer, index) {
    setSlideOver({
      childComponent: (
        <ManufacturersForm
          manufacturer={manufacturer}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateManufacturer}
        />
      ),
      open: true,
    });
  };

  return {
    getManufacturers,
    loading,
    manufacturers,
    openAddManufacturerSlideOver,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    viewManufacturerDetails,
  };
}
