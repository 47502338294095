import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { SimpleNotificationType } from "../../components/SimpleNotification";

export function ProductReportViewModel(logout, isFromReportsScreen) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const [manufacturedProduct, setManufacturedProduct] = useState({
    ingredients: [],
  });

  const getProductReportById = async function (productId) {
    console.log("getProductReportById aclled", productId);
    const payload = {
      product_id: productId,
    };
    let callResult = await makePostApiCall(
      `production-reports/id/${productId}`,
      payload
    );

    if (callResult.success) {
      setManufacturedProduct(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getProductReportByPlu = async function (plu) {
    console.log("getProductReportByPlu aclled");
    const payload = {
      plu: plu,
    };
    let callResult = await makePostApiCall(
      `production-reports/plu/${plu}`,
      payload
    );

    if (callResult.success) {
      setManufacturedProduct(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    emptyModal,
    getProductReportById,
    getProductReportByPlu,
    manufacturedProduct,
    setEmptyModelOpen,
    showSimpleNotification,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
  };
}
