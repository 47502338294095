import React, { useRef, useState } from "react";

import Input from "../../../controls/Input";

import { format } from "date-fns";
import { classNames, ingestBarcodeScan } from "../../../../shared/utility";

import ProductReportDetailsComponent1 from "./components/ProductReportDetailsComponent1";
import ProductReportDetailsComponent2 from "./components/ProductReportDetailsComponent2";
import ProductReportDetailsComponent3 from "./components/ProductReportDetailsComponent3";
import ProductReportDetailsComponent4 from "./components/ProductReportDetailsComponent4";
import ProductReportDetailsComponent5 from "./components/ProductReportDetailsComponent5";
import ProductReportDetailsComponent6 from "./components/ProductReportDetailsComponent6";
import ProductReportDetailsComponent7 from "./components/ProductReportDetailsComponent7";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export default function ProductionReportForm(props) {
  const { manufacturedProduct, setEmptyModelOpen } = props;

  const [barcodeScanInProgress, setBarcodeScanInProgress] = useState(false);
  const [clickedIngredient, setClickedIngredient] = useState({});

  const searchRef = useRef();

  return (
    <div className="px-4 sm:px-6 lg:px-4">
      {/* <div className="sm:flex sm:items-center bg-white px-4 py-5 border-b border-gray-200 sm:px-4 rounded-xl"> */}
      <div className="mt-5 grid grid-cols-4 gap-4">
        <div className="col-span-1">
          <div className="col-span-2 sm:items-center px-1 pb-3 flex relative flex items-stretch flex-grow focus-within:z-10">
            <input
              type="search"
              name="searchInput"
              id="searchInput"
              className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none py-2"
              placeholder="Type or Scan Lot Number"
              ref={searchRef}
            />
            <button
              type="button"
              className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
              // onClick={() => handleSearch(searchRef.current.value)}
            >
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="mt-3">
            <ProductReportDetailsComponent1
              setEmptyModelOpen={setEmptyModelOpen}
              values={manufacturedProduct}
            />
          </div>
          <div className="mt-3">
            <ProductReportDetailsComponent6 values={manufacturedProduct} />
          </div>
        </div>
        <div className="col-span-2">
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1">
              <ProductReportDetailsComponent2 values={manufacturedProduct} />
            </div>
            <div className="col-span-1">
              <ProductReportDetailsComponent3 values={clickedIngredient} />
            </div>
          </div>
          <div className="col-span-2 mt-3">
            <ProductReportDetailsComponent7 values={clickedIngredient} />
          </div>
        </div>
        <div className="col-span-1">
          <ProductReportDetailsComponent4 values={clickedIngredient} />
          <div className="mt-3">
            <ProductReportDetailsComponent5 values={clickedIngredient} />
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Product Lot/Serial
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Supplier
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Item Plu
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Item Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Used Weight
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {manufacturedProduct.ingredients.length > 0 ? (
                      manufacturedProduct.ingredients.map(
                        (assemblyItem, index) => (
                          <tr
                            key={assemblyItem.id}
                            className={classNames(
                              assemblyItem.in_stock === 0
                                ? "bg-red-100"
                                : assemblyItem.is_used
                                ? "bg-cyan-100"
                                : null
                            )}
                            onClick={() => setClickedIngredient(assemblyItem)}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {assemblyItem.product_serial}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {assemblyItem.supplier_name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {assemblyItem.box_plu}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {assemblyItem.product_name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {assemblyItem.ingredient_amount_used} (kg)
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan={6} className="py-5 text-center">
                          Please choose an ingredient
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
