import { useState } from "react";

import Dropzone from "react-dropzone";

import { classNames, convertToDefEventPara } from "../../shared/utility";

export default function ImportMediaDropzone(props) {
  const { fileName, handleInputChange } = props;

  const [error, setError] = useState(null);

  return (
    <div className="mt-2">
      <div>
        <Dropzone
          onDrop={(acceptedFile) => {
            if (acceptedFile.length > 0) {
              handleInputChange(convertToDefEventPara(fileName, acceptedFile));
            } else {
              setError("Could not upload file, please try again.");
            }
          }}
          maxFiles={1}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({})}>
              <button className="rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                <input {...getInputProps()} />
                Upload image
              </button>
            </div>
            // <section>
            //   <div
            //     {...getRootProps({
            //       className:
            //         "relative block w-full bg-blue-50 border-2 border-blue-300 border-solid rounded-lg p-5 text-center hover:border-gray-400 cursor-pointer",
            //     })}
            //   >
            //     <input {...getInputProps()} />
            //     <div className="text-center">
            //       <p className="hidden sm:block mt-3 text-gray-700 font-semibold">
            //         Click here to upload file.
            //       </p>
            //       <div className="mt-2 mx-auto flex items-center justify-center text-sm">
            //         <InformationCircleIcon
            //           className="h-5 w-5 text-gray-600"
            //           aria-hidden="true"
            //         />
            //         <p className="ml-1 text-gray-700">
            //           Following media types are supported:
            //         </p>
            //       </div>
            //       <p className="text-gray-700 text-sm">.jpg, .jpeg, .png</p>
            //     </div>
            //   </div>
            // </section>
          )}
        </Dropzone>
      </div>
      <div className={classNames(error ? "text-center p-4" : "hidden")}>
        <h3 className="text-lg leading-6 font-normal text-red-500">{error}</h3>
      </div>
    </div>
  );
}
