import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import CustomerDetailsForm from "../../../components/forms/setup/customer/CustomerDetailsForm";
import { SimpleNotificationType } from "../../../components/SimpleNotification";

export function CustomersViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState({
    data: [],
  });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id");

  const getCustomers = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`customers`, payload);
    setLoading(false);
    if (callResult.success) {
      setCustomers(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createCustomer = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall("createCustomer", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Customer added",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getCustomers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateCustomer = async function (customer, values, index) {
    let callResult = await makePutApiCall(`customers/${customer.id}`, values);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Customer updated",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getCustomers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddCustomerSlideOver = () => {
    setSlideOver({
      childComponent: (
        <CustomerDetailsForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createCustomer}
        />
      ),
      open: true,
    });
  };

  const viewCustomerDetails = async function (customer, index) {
    setSlideOver({
      childComponent: (
        <CustomerDetailsForm
          customer={customer}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateCustomer}
        />
      ),
      open: true,
    });
  };

  return {
    createCustomer,
    getCustomers,
    loading,
    customers,
    openAddCustomerSlideOver,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    updateCustomer,
    viewCustomerDetails,
  };
}
