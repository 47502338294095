import React, { useState } from "react";

import { useForm } from "../useForm";

import { format } from "date-fns";
import {
  classNames,
  ingestBarcodeScan,
  updateObjectInArray,
} from "../../../shared/utility";

import NewPackageDetails1 from "./newComponents/NewPackageDetails1";
import NewPackageDetails2 from "./newComponents/NewPackageDetails2";
import ModelErrorComponent from "../../modals/ModelErrorComponent";

const initialFValues = {
  product_id: "",
  production_product_id: "",
  product_date: "",
  best_before_date: "",
  packing_size: "",
  shelf_life: "",
  serial_number: "",
  supervisor: "",
  employee_1: "",
  employee_2: "",
  room_temperature: "",
  product_temperature: "",
  shipping_barcode: "",
  description_en: "",
  brand: "",
};

export default function NewPackageForm(props) {
  const { getManufacturedItemByLot, setEmptyModelOpen, setSlideOver } = props;

  const [barcodeScanInProgress, setBarcodeScanInProgress] = useState(false);
  const [manufactureInProgress, setManufactureInProgress] = useState(false);
  const [productAssemblyItems, setProductAssemblyItems] = useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.time_start = null;
    if (values.time_start === "") {
      temp.time_start = 'Please click "Start production"';
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleBarcodeScan = async function (scannedBarcode) {
    if (scannedBarcode || scannedBarcode !== "") {
      console.log("scannedBarcode", scannedBarcode);
      // setBarcodeScanInProgress(true);

      // const barcodeInfo = ingestBarcodeScan(scannedBarcode);

      // const barcodePlu = barcodeInfo.plu;

      // if (barcodePlu || barcodePlu !== "") {
      const fetchedItem = await getManufacturedItemByLot(scannedBarcode);

      if (fetchedItem) {
        setValues(fetchedItem.data);
      } else {
        setEmptyModelOpen({
          childComponent: (
            <ModelErrorComponent
              setOpen={setEmptyModelOpen}
              subtitle="We could not find that product!"
              title="Oops"
            />
          ),
          open: true,
        });
      }
      // }

      setBarcodeScanInProgress(false);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-4">
      <div className="sm:flex sm:items-center bg-white px-4 py-5 border-b border-gray-200 sm:px-4 rounded-xl">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Package products
          </h1>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-4 gap-4">
        <NewPackageDetails1
          errors={errors}
          handleBarcodeScan={handleBarcodeScan}
          handleInputChange={handleInputChange}
          values={values}
        />
        <NewPackageDetails2
          errors={errors}
          handleInputChange={handleInputChange}
          values={values}
        />
      </div>
      <div className="mt-5">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Product Lot/Serial
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Supplier
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Item Plu
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Item Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Used Weight
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {productAssemblyItems.length > 0 ? (
                      productAssemblyItems.map((assemblyItem, index) => (
                        <tr
                          key={assemblyItem.id}
                          className={classNames(
                            assemblyItem.in_stock === 0
                              ? "bg-red-100"
                              : assemblyItem.is_used
                              ? "bg-cyan-100"
                              : null
                          )}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {assemblyItem.product_lot}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.supplier_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.box_plu}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.product_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.amount_to_use} (kg)
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            {/* <button
                                  onClick={() => {
                                    viewProductTypeDetails(productType, index);
                                  }}
                                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  Edit
                                </button> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="py-5 text-center">
                          Please start scanning products
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
