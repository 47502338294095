import React from "react";

import Input from "../../../controls/Input";
import { formatDate } from "../../../../shared/utility";

export default function NewPackageDetails2(props) {
  const { certifiedByOptions, errors, handleInputChange, values } = props;

  return (
    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="col-span-2">
          <Input
            label="Product Plu"
            name="plu"
            onChange={handleInputChange}
            value={values.plu}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.supervisor}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Product name"
            name="product_name"
            onChange={handleInputChange}
            value={values.product_name}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.employee_1}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Product description"
            name="description_en"
            onChange={handleInputChange}
            value={values.description_en}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.employee_1}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Production date"
            name="created_at"
            onChange={handleInputChange}
            value={formatDate(values.created_at)}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Best before date"
            name="production_room"
            onChange={handleInputChange}
            value={formatDate(values.shelf_life_date)}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Packing size"
            name="packing_size"
            onChange={handleInputChange}
            value={values.packing_size}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Shelf life"
            name="shelf_life_date"
            onChange={handleInputChange}
            value={values.shelf_life}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Serial number"
            name=""
            onChange={handleInputChange}
            value={""}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Label to print"
            name="production_room"
            onChange={handleInputChange}
            value={""}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Lot number"
            name="production_room"
            onChange={handleInputChange}
            value={values.new_lot_number}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
      </div>
    </div>
  );
}
