import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { SimpleNotificationType } from "../../components/SimpleNotification";

export function NewPackageViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getManufacturedItemByLot = async function (lot) {
    const payload = {
      lot: lot,
    };
    let callResult = await makePostApiCall(
      `/production-reports/lot/${lot}`,
      payload
    );

    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    emptyModal,
    getManufacturedItemByLot,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    showSimpleNotification,
    slideOver,
  };
}
