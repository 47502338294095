import { useEffect } from "react";

import { AddReceivingInvoiceViewModel } from "../../../viewmodels/receiving/invoices/AddReceivingInvoiceViewModel";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { withRouter } from "../../../shared/utility";

import SlideOver from "../../../components/slideOver/SlideOver";
import AddReceivingInvoiceForm from "../../../components/forms/receiving/invoices/AddReceivingInvoiceForm";
import SimpleNotification from "../../../components/SimpleNotification";

function AddReceivingInvoice(props) {
  const { logout } = props;
  const {
    addItemToReceivingInvoice,
    createReceivingInvoice,
    getAllAssemblyItems,
    getAllergensForDropdown,
    getAssemblyItemByPlu,
    getClaimsForDropdown,
    getProductTypes,
    invoice,
    invoiceItems,
    onGenerateBarcodePdf,
    productTypeOptions,
    claimsOptions,
    allergensOptions,
    loading,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
  } = AddReceivingInvoiceViewModel(logout);

  useEffect(() => {
    getAllergensForDropdown();
    getClaimsForDropdown();
    getProductTypes();
  }, []);

  return (
    <div>
      <div className="mt-10">
        <AddReceivingInvoiceForm
          addItemToReceivingInvoice={addItemToReceivingInvoice}
          createReceivingInvoice={createReceivingInvoice}
          invoice={invoice}
          onGenerateBarcodePdf={onGenerateBarcodePdf}
          productTypeOptions={productTypeOptions}
          claimsOptions={claimsOptions}
          allergensOptions={allergensOptions}
          getAllAssemblyItems={getAllAssemblyItems}
          getAssemblyItemByPlu={getAssemblyItemByPlu}
          setSlideOver={setSlideOver}
          getProductTypes={getProductTypes}
          isEdit={false}
          invoiceItems={invoiceItems}
        />
      </div>

      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-3xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddReceivingInvoice)
);
