import React from "react";

import Input from "../../../../controls/Input";

export default function ProductReportDetailsComponent4(props) {
  const { values } = props;

  return (
    <div className="bg-gray-200 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl divide-y divide-gray-200">
      <div>
        <h1 className="text-xl px-4 py-2">Box Details</h1>
      </div>
      <div className="bg-white p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="col-span-2">
            <Input
              label="Manufacturer"
              name="values.manufacturer_name"
              value={values.manufacturer_name}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Box Weight"
              name="total_weight"
              value={values.total_weight}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Box PLU"
              name="box_plu"
              value={values.box_plu}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-2">
            <Input
              label="Box Barcode"
              name="box_barcode"
              value={values.box_barcode}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Box Lot"
              name="product_serial"
              value={values.product_serial}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Box Date"
              name="box_date"
              value={values.box_date}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
