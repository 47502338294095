import React, { useEffect } from "react";

import { useForm } from "../../useForm";

import Input from "../../../controls/Input";

import ImportMediaDropzone from "../../../importMedia/ImportMediaDropzone";

import { config } from "../../../../constants";
import SelectMenu from "../../../controls/SelectMenu";

const initialFValues = {
  plu: "",
  name_en: "",
  name_fr: "",
  description_en: "",
  description_fr: "",
  pro_group: "",
  ingredients_en: "",
  ingredients_fr: "",
  weight: "",
  shelf_life: "",
  price: "",
  barcode: "",
  storage_description_en: "",
  storage_description_fr: "",
  cooking_instructions_en: "",
  cooking_instructions_fr: "",
  allergens_en: "",
  allergens_fr: "",
};

export default function ProductDetailsForm(props) {
  const { allergensOptions, product, actionCall, isEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleInputChange,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEdit) {
      actionCall(product, values);
    } else {
      actionCall(values);
    }
  };

  useEffect(() => {
    if (isEdit && product) {
      setValues(product);
    }
  }, [isEdit, product, setValues]);

  const handleAllergensInput = (e) => {
    const newValues = e.target.value;
    var newAllergensEN = "";
    var newAllergensFR = "";
    newValues.forEach((element, index) => {
      if (index === 0) {
        newAllergensEN = element.label;
        newAllergensFR = element.label;
      } else {
        newAllergensEN += ", " + element.label;
        newAllergensFR += ", " + element.label;
      }
    });
    handleMultipleInputChange(
      { allergens: newValues },
      { allergens_en: newAllergensEN },
      { allergens_fr: newAllergensFR }
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="justify-between px-4">
        <div className="divide-y divide-gray-500">
          <div className="pt-6 pb-5 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="mt-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {isEdit ? "Product details" : "Add new product"}
              </h3>
            </div>
          </div>

          {/* here */}
          <div className="space-y-3 pt-6 pb-5">
            <div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Plu"
                    name="plu"
                    onChange={handleInputChange}
                    value={values.plu}
                    labelOn={true}
                  />
                  <Input
                    label="Barcode"
                    name="barcode"
                    onChange={handleInputChange}
                    value={values.barcode}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Name (EN)"
                    name="name_en"
                    onChange={handleInputChange}
                    value={values.name_en}
                    labelOn={true}
                  />
                </div>
                <div>
                  <Input
                    label="Name (FR)"
                    name="name_fr"
                    onChange={handleInputChange}
                    value={values.name_fr}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Description (EN)"
                    name="description_en"
                    onChange={handleInputChange}
                    value={values.description_en}
                    labelOn={true}
                  />
                </div>
                <div>
                  <Input
                    label="Description (FR)"
                    name="description_fr"
                    onChange={handleInputChange}
                    value={values.description_fr}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <div className="flex space-x-2">
                    <label
                      htmlFor="productDescription"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Description
                    </label>
                  </div>
                  <textarea
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg"
                    label="Ingredients (EN)"
                    name="ingredients_en"
                    onChange={handleInputChange}
                    value={values.cooking_instructions_en}
                    rows="4"
                  />
                </div>
                <div>
                  <div className="flex space-x-2">
                    <label
                      htmlFor="productDescription"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Description
                    </label>
                  </div>
                  <textarea
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg"
                    label="Ingredients (FR)"
                    name="ingredients_fr"
                    onChange={handleInputChange}
                    value={values.cooking_instructions_en}
                    rows="4"
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Pro group"
                    name="pro_group"
                    onChange={handleInputChange}
                    value={values.pro_group}
                    labelOn={true}
                  />
                </div>
                <div>
                  <Input
                    label="Weight"
                    name="weight"
                    onChange={handleInputChange}
                    value={values.weight}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Shelf life"
                    name="shelf_life"
                    onChange={handleInputChange}
                    value={values.shelf_life}
                    labelOn={true}
                  />
                </div>
                <div>
                  <Input
                    label="Price"
                    name="price"
                    onChange={handleInputChange}
                    value={values.price}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <Input
                    label="Storage description (EN)"
                    name="storage_description_en"
                    onChange={handleInputChange}
                    value={values.storage_description_en}
                    labelOn={true}
                  />
                </div>
                <div>
                  <Input
                    label="Storage description (FR)"
                    name="storage_description_fr"
                    onChange={handleInputChange}
                    value={values.storage_description_fr}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <div className="flex space-x-2">
                    <label
                      htmlFor="productDescription"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Description
                    </label>
                  </div>
                  <textarea
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg"
                    label="Cooking instructions (EN)"
                    name="cooking_instructions_en"
                    onChange={handleInputChange}
                    value={values.cooking_instructions_en}
                    rows="4"
                  />
                </div>
                <div>
                  <div className="flex space-x-2">
                    <label
                      htmlFor="productDescription"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Description
                    </label>
                  </div>
                  <textarea
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg"
                    label="Cooking instructions (FR)"
                    name="cooking_instructions_fr"
                    onChange={handleInputChange}
                    value={values.cooking_instructions_en}
                    rows="4"
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
                <div>
                  <SelectMenu
                    isMulti={true}
                    handleInputChange={(e) => {
                      handleAllergensInput(e);
                    }}
                    name="allergens"
                    menuPlacement="top"
                    options={allergensOptions}
                    defaultValue={isEdit ? product.allergens : null}
                    title={"Allergens"}
                  />
                </div>
                <div>
                  <Input
                    label="Allergens (FR)"
                    name="allergens_fr"
                    disabled={true}
                    value={values.allergens_fr}
                    labelOn={true}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4">
                <div className="grid-col-1">
                  <div className="flex space-x-2">
                    <label
                      htmlFor="image1"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Image
                    </label>
                  </div>
                  {isEdit ? (
                    <img
                      src={config.url.STORAGE_URL + product.image}
                      alt={product.name_en}
                      title={product.name_en}
                      className="mx-auto media-image rounded-md"
                    />
                  ) : null}
                  <ImportMediaDropzone
                    fileName="image1"
                    handleInputChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="sm:flex-no-wrap">
              <div className="my-auto flex items-center">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center mt-3"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
