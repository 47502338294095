import React from "react";

import Input from "../../../controls/Input";

export default function ManufactureNewDetails2(props) {
  const { errors, handleInputChange, values } = props;

  return (
    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="col-span-2">
          <Input
            label="Plu"
            name="plu"
            onChange={handleInputChange}
            value={values.plu}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.plu}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Product Name"
            name="product_name"
            onChange={handleInputChange}
            value={values.product_name}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.product_name}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Description"
            name="description_en"
            onChange={handleInputChange}
            value={values.description_en}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.description_en}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="New Lot Number"
            name="new_lot_number"
            onChange={handleInputChange}
            value={values.new_lot_number}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.new_lot_number}
          />
        </div>
      </div>
    </div>
  );
}
