import {
  Cog6ToothIcon,
  HomeIcon,
  UsersIcon,
  ArchiveBoxArrowDownIcon,
  HomeModernIcon,
  ScaleIcon,
  TruckIcon,
  ArrowPathRoundedSquareIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";

export default function routes(pathname, userType) {
  var title;
  var routes;

  routes = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Receiving",
      icon: ArchiveBoxArrowDownIcon,
      current: false,
      showInMenu: true,
      children: [
        { name: "Records", href: "/receiving-index", current: false },
        { name: "Invoices", href: "/receiving-invoices", current: false },
      ],
    },
    {
      name: "Production",
      icon: HomeModernIcon,
      current: false,
      showInMenu: true,
      children: [
        { name: "Records", href: "/production-reports", current: false },
        {
          name: "Manufacture Product",
          href: "/manufacture-product",
          current: false,
        },
      ],
    },
    {
      name: "Packaging",
      icon: ScaleIcon,
      current: false,
      showInMenu: true,
      children: [
        { name: "Records", href: "/packaging-reports", current: false },
        {
          name: "Package Product",
          href: "/packaging-new",
          current: false,
        },
      ],
    },
    {
      name: "Shipping",
      icon: TruckIcon,
      current: false,
      showInMenu: true,
      open: false,
      children: [
        { name: "Record", href: "#" },
        { name: "Production", href: "#" },
        { name: "Packaging", href: "#" },
        { name: "Order Prep", href: "#" },
        { name: "Ship", href: "#" },
      ],
    },
    {
      name: "Traceability",
      href: "#",
      icon: ArrowPathRoundedSquareIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "QA Report",
      href: "#",
      icon: ChartBarIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Setup",
      icon: Cog6ToothIcon,
      current: false,
      showInMenu: true,
      open: false,
      children: [
        {
          name: "Allergens",
          href: "/product-allergens",
          current: false,
        },
        { name: "Assembly items", href: "/assembly-items", current: false },
        { name: "Brands", href: "/brands", current: false },
        {
          name: "Certification Types",
          href: "/certification-types",
          current: false,
        },
        { name: "Claims", href: "/product-claims", current: false },
        { name: "Customers", href: "/customers", current: false },
        { name: "Manufacturers", href: "/manufacturers", current: false },
        { name: "My Products", href: "/my-products", current: false },
        { name: "Preservatives", href: "/preservatives", current: false },
        { name: "Suppliers", href: "/Suppliers", current: false },
        { name: "Types", href: "/product-types", current: false },
      ],
    },
    {
      name: "Users",
      href: "/users",
      icon: UsersIcon,
      current: false,
      showInMenu: userType === "1",
    },
  ];

  routes.forEach((route) => {
    route.open = false;
    if (route.children) {
      route.children.forEach((child) => {
        child.current = false;
        if (child.href === pathname) {
          route.open = true;
          child.current = true;

          title = child.name;
        }
      });
    } else if (route.href === pathname) {
      route.current = true;

      title = route.name;
    }
  });

  var returnArray = {
    routes: routes.filter((route) => route.showInMenu),
    title: title,
  };

  return returnArray;
}
