import { Dialog } from "@headlessui/react";

import { config } from "../../../../constants";

export default function PrintBarcodeOrBatchModal(props) {
  const { barcodeUrl, batchUrl, setOpen } = props;

  return (
    <div>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            What would you like to print?
          </Dialog.Title>
          {/* <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo
                        pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.
                      </p>
                    </div> */}
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2"
          onClick={() => {
            window
              .open(config.url.STORAGE_URL + "/" + barcodeUrl, "_blank")
              .focus();
            setOpen({
              childComponent: null,
              open: false,
            });
          }}
        >
          Batch Barcode
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => {
            window
              .open(config.url.STORAGE_URL + "/" + batchUrl, "_blank")
              .focus();
            setOpen({
              childComponent: null,
              open: false,
            });
          }}
        >
          Batch Report
        </button>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2"
          onClick={() => {
            window
              .open(config.url.STORAGE_URL + "/" + barcodeUrl, "_blank")
              .focus();
            setOpen({
              childComponent: null,
              open: false,
            });
          }}
        >
          Nitrate & Phosphate Report
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => {
            window
              .open(config.url.STORAGE_URL + "/" + batchUrl, "_blank")
              .focus();
            setOpen({
              childComponent: null,
              open: false,
            });
          }}
        >
          Cooking & Cooling
        </button>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2"
          onClick={() => {
            window
              .open(config.url.STORAGE_URL + "/" + barcodeUrl, "_blank")
              .focus();
            setOpen({
              childComponent: null,
              open: false,
            });
          }}
        >
          Label Check Report
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => {
            window
              .open(config.url.STORAGE_URL + "/" + batchUrl, "_blank")
              .focus();
            setOpen({
              childComponent: null,
              open: false,
            });
          }}
        >
          Vacuum Pack
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => {
            window
              .open(config.url.STORAGE_URL + "/" + barcodeUrl, "_blank")
              .focus();
            setOpen({
              childComponent: null,
              open: false,
            });
          }}
        >
          Metal Detector
        </button>
      </div>
    </div>
  );
}
