import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import { SimpleNotificationType } from "../../../components/SimpleNotification";

export function ProductViewModel(logout, productId) {
  const [allergensOptions, setAllergensOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });

  const user_id = localStorage.getItem("id") * 1;

  const getProduct = async function (search, page) {
    setLoading(true);
    const payload = { id: productId };
    let callResult = await makePostApiCall(`products/${productId}`, payload);
    setLoading(false);
    if (callResult.success) {
      setProduct(callResult.data.product);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createProduct = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall(
      "createProduct",
      payload,
      "multipart/form-data"
    );

    if (callResult.success) {
      window.location = "/my-products/" + callResult.data.product.id;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateProduct = async function (product, values) {
    let callResult = await makePutApiCall(`products/${product.id}`, values);
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Product updated",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getAllergensForDropdown = async function () {
    let callResult = await makePostApiCall(`allergensForDropdown`);

    if (callResult.success) {
      setAllergensOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    allergensOptions,
    createProduct,
    getAllergensForDropdown,
    getProduct,
    loading,
    product,
    setShowSimpleNotification,
    showSimpleNotification,
    updateProduct,
  };
}
