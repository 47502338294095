import React from "react";

import Input from "../../../../controls/Input";
import { formatDate } from "../../../../../shared/utility";

export default function ProductReportDetailsComponent7(props) {
  const { values } = props;

  return (
    <div className="bg-gray-200 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl divide-y divide-gray-200">
      <div>
        <h1 className="text-xl px-4 py-2">Traceability</h1>
      </div>
      <div className="bg-white p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="col-span-2">
              <Input
                label="Item Name"
                name="product_name"
                value={values.product_name}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Item PLU"
                name="box_plu"
                value={values.box_plu}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Item Serial"
                name="product_serial"
                value={values.product_serial}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-2 flex">
              <input
                id="quick_scan"
                aria-describedby="selectAllBox"
                name="quick_scan"
                type="checkbox"
                checked={values.quick_scan}
                className="mt-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                //   onClick={(e) =>
                //     handleInputChange(
                //       convertToDefEventPara("quick_scan", e.target.checked)
                //     )
                //   }
              />
              Show Receiving Record Detail
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="col-span-2">
              <Input
                label="Manual Entry Lot #"
                name="plu"
                //value={values.plu}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Item Packdate"
                name="product_name"
                //value={values.product_name}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Item Weight"
                name="pack_weight"
                value={values.pack_weight}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
            <div className="col-span-1">
              <Input
                label="Item Received Date"
                name="received_date"
                value={formatDate(values.received_date)}
                labelOn={true}
                disabled={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
