import { useEffect } from "react";
import { NewPackageViewModel } from "../../viewmodels/packaging/NewPackageViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";
import SimpleNotification from "../../components/SimpleNotification";

import NewPackageForm from "../../components/forms/packaging/NewPackageForm";
import EmptyModal from "../../components/modals/EmptyModal";

function NewPackageScreen(props) {
  const { logout } = props;
  const {
    getManufacturedItemByLot,
    emptyModal,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    showSimpleNotification,
    slideOver,
  } = NewPackageViewModel(logout);

  useEffect(() => {}, []);

  const openSerialPort = async () => {
    const ports = await navigator.serial.getPorts();
    console.log("ports", ports);
    const port = await navigator.serial.requestPort();
    await port.open({
      baudRate: 6000,
    });

    // Listen to data coming from the serial device.
    const reader = port.readable.getReader();
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        // Allow the serial port to be closed later.
        reader.releaseLock();
        break;
      }
      // value is a Uint8Array.
      console.log(value);
    }
  };

  return (
    // <div>
    //   <button onClick={() => openSerialPort()}> Open Serial Port </button>
    // </div>

    <div>
      <div className="mt-2">
        <NewPackageForm
          getManufacturedItemByLot={getManufacturedItemByLot}
          setEmptyModelOpen={setEmptyModelOpen}
          setSlideOver={setSlideOver}
        />
      </div>

      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-7xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewPackageScreen)
);
