import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import SuppliersForm from "../../../components/forms/setup/suppliers/SuppliersForm";
import { SimpleNotificationType } from "../../../components/SimpleNotification";

export function SuppliersViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState({ data: [] });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id");

  const getSuppliers = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`suppliers`, payload);
    setLoading(false);
    if (callResult.success) {
      setSuppliers(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createSupplier = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall("createSupplier", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Supplier added",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getSuppliers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateSupplier = async function (supplier, values, index) {
    let callResult = await makePutApiCall(`supplier/${supplier.id}`, values);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Supplier updated",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getSuppliers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddSupplierSlideOver = () => {
    setSlideOver({
      childComponent: (
        <SuppliersForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createSupplier}
        />
      ),
      open: true,
    });
  };

  const viewSupplierDetails = async function (supplier, index) {
    setSlideOver({
      childComponent: (
        <SuppliersForm
          supplier={supplier}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateSupplier}
        />
      ),
      open: true,
    });
  };

  return {
    getSuppliers,
    loading,
    suppliers,
    openAddSupplierSlideOver,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    viewSupplierDetails,
  };
}
