import React from "react";

import Input from "../../../../controls/Input";
import PrintBarcodeOrBatchModal from "../../components/PrintBarcodeOrBatchModal";

export default function ProductReportDetailsComponent1(props) {
  const { setEmptyModelOpen, values } = props;

  return (
    <div className="bg-gray-200 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl divide-y divide-gray-200">
      <div>
        <h1 className="text-xl px-4 py-2">Product</h1>
      </div>
      <div className="bg-white p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="col-span-2">
            <Input
              label="PLU"
              name="plu"
              value={values.plu}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-2">
            <Input
              label="Product Name"
              name="product_name"
              value={values.product_name}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-2">
            <Input
              label="Product Description"
              name="description_en"
              value={values.description_en}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Product Weight"
              name="new_weight"
              value={values.new_weight}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Total Raw Material Cost"
              name="total_raw_material_cost"
              value={values.total_raw_material_cost}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <button
              type="button"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() =>
                setEmptyModelOpen({
                  childComponent: (
                    <PrintBarcodeOrBatchModal
                      barcodeUrl={""}
                      batchUrl={""}
                      setOpen={setEmptyModelOpen}
                    />
                  ),
                  open: true,
                })
              }
            >
              Print Production Tag
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
