import { useState } from "react";

import { makeGetApiCall, makePostApiCall } from "../../../shared/makeApiCall";
import UserDetailsForm from "../../../components/forms/user/UserDetailsForm";
import { SimpleNotificationType } from "../../../components/SimpleNotification";

export function UsersViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({
    data: [],
  });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id") * 1;

  const getUsers = async function (search, page) {
    console.log("page", page);
    setLoading(true);
    let callResult = await makeGetApiCall(
      `users?page=${page}&search=${search}`
    );
    setLoading(false);
    if (callResult.success) {
      setUsers(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createUser = async function (values) {
    const payload = { ...values, user_id: user_id };
    console.log("createUser", payload);
    let callResult = await makePostApiCall("createUser", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "User added",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getUsers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateUser = async function (user, values, index) {
    let callResult = await makePostApiCall(`users/${user.id}`, values);

    if (callResult.success) {
      console.log("updateUser", callResult.data);
      setShowSimpleNotification({
        show: true,
        title: "User updated",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getUsers("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddUserSlideOver = () => {
    setSlideOver({
      childComponent: (
        <UserDetailsForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createUser}
        />
      ),
      open: true,
    });
  };

  const viewUserDetails = async function (user, index) {
    setSlideOver({
      childComponent: (
        <UserDetailsForm
          user={user}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updateUser}
        />
      ),
      open: true,
    });
  };

  return {
    openAddUserSlideOver,
    getUsers,
    loading,
    users,
    setSlideOver,
    slideOver,
    setShowSimpleNotification,
    showSimpleNotification,
    viewUserDetails,
  };
}
