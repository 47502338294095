import React from "react";

import Input from "../../../controls/Input";
import SelectMenu from "../../../controls/SelectMenu";

export default function ManufactureNewDetails(props) {
  const { certifiedByOptions, errors, handleInputChange, values } = props;

  return (
    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="col-span-2">
          <Input
            label="Supervisor"
            name="supervisor"
            onChange={handleInputChange}
            value={values.supervisor}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.supervisor}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Employee 1"
            name="employee_1"
            onChange={handleInputChange}
            value={values.employee_1}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.employee_1}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Employee 2"
            name="employee_2"
            onChange={handleInputChange}
            value={values.employee_2}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.employee_2}
          />
        </div>
        <div className="col-span-1">
          <SelectMenu
            handleInputChange={handleInputChange}
            name="certification_type"
            options={certifiedByOptions}
            title={"Certified by"}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Production Room"
            name="production_room"
            onChange={handleInputChange}
            value={values.production_room}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Production Room Temp &deg;C"
            name="production_room_temperature"
            onChange={handleInputChange}
            value={values.production_room_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room_temperature}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Raw Product Temp &deg;C"
            name="raw_product_temperature"
            onChange={handleInputChange}
            value={values.raw_product_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.raw_product_temperature}
          />
        </div>
      </div>
    </div>
  );
}
