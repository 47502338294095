import React from "react";

import Input from "../../../../controls/Input";

export default function ProductReportDetailsComponent6(props) {
  const { values } = props;

  return (
    <div className="bg-gray-200 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl divide-y divide-gray-200">
      <div>
        <h1 className="text-xl px-4 py-2">Employees</h1>
      </div>
      <div className="bg-white p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="col-span-2">
            <Input
              label="Supervisor"
              name="supervisor"
              value={values.supervisor}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Employee 1"
              name="employee_1"
              value={values.employee_1}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Employee 2"
              name="employee_2"
              value={values.employee_2}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
