import React, { useState } from "react";

import { useForm } from "../useForm";

import Input from "../../controls/Input";

import ManufactureNewDetails from "./components/ManufactureNewDetails";
import ManufactureNewDetails2 from "./components/ManufactureNewDetails2";
import ManufactureNewDetails3 from "./components/ManufactureNewDetails3";
import ProductsTableComponent from "./components/ProductsTableComponent";
import { format } from "date-fns";
import {
  classNames,
  ingestBarcodeScan,
  updateObjectInArray,
} from "../../../shared/utility";
import AddAssemblyItemToProductForm from "./components/AddAssemblyItemToProductForm";
import CurrentIngredientsList from "./components/CurrentIngredientsList";

import ModelErrorComponent from "../../modals/ModelErrorComponent";
import PrintBarcodeOrBatchModal from "./components/PrintBarcodeOrBatchModal";

const initialFValues = {
  created_date: "",
  time_start: "",
  supervisor: "",
  employee_1: "",
  employee_2: "",
  production_room: "",
  production_room_temperature: "",
  raw_product_temperature: "",
  product_id: "",
  plu: "",
  product_name: "",
  description_en: "",
  description_fr: "",
  new_lot_number: "",
  new_weight: "",
  new_product_temperature: "",
  allergens: "",
  corrective_action: "",
  scanned_assembly_barcode: "",
  quick_scan: "",
  ingredients: [],
};

export default function ManufactureNewProductForm(props) {
  const {
    certifiedByOptions,
    getAssemblyItemReceivingByBarcode,
    getProductsList,
    finishManufacturing,
    setEmptyModelOpen,
    setSlideOver,
  } = props;

  const [barcodeScanInProgress, setBarcodeScanInProgress] = useState(false);
  const [manufactureInProgress, setManufactureInProgress] = useState(false);
  const [productAssemblyItems, setProductAssemblyItems] = useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.time_start = null;
    if (values.time_start === "") {
      temp.time_start = 'Please click "Start production"';
    }

    temp.supervisor = null;
    if (values.supervisor === "") {
      temp.supervisor = "Please fill out field.";
    }

    temp.employee_1 = null;
    if (values.employee_1 === "") {
      temp.employee_1 = "Please fill out field.";
    }

    temp.production_room = null;
    if (values.production_room === "") {
      temp.production_room = "Please fill out field.";
    }

    temp.production_room_temperature = null;
    if (values.production_room_temperature === "") {
      temp.production_room_temperature = "Please fill out field.";
    }

    temp.raw_product_temperature = null;
    if (values.raw_product_temperature === "") {
      temp.raw_product_temperature = "Please fill out field.";
    }

    temp.plu = null;
    if (values.plu === "") {
      temp.plu = "Please fill out field.";
    }

    temp.product_name = null;
    if (values.product_name === "") {
      temp.product_name = "Please fill out field.";
    }

    temp.description_en = null;
    if (values.description_en === "") {
      temp.description_en = "Please fill out field.";
    }

    temp.new_lot_number = null;
    if (values.new_lot_number === "") {
      temp.new_lot_number = "Please fill out field.";
    }

    temp.new_weight = null;
    if (values.new_weight === "") {
      temp.new_weight = "Please fill out field.";
    }

    temp.new_product_temperature = null;
    if (values.new_product_temperature === "") {
      temp.new_product_temperature = "Please fill out field.";
    }

    temp.ingredients = null;
    if (!values.ingredients.length > 0) {
      temp.ingredients = "Please fill out field.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const openSearchProductsList = async function () {
    let callResult = await getProductsList("");
    if (callResult.success) {
      setSlideOver({
        childComponent: (
          <ProductsTableComponent
            getProductsList={getProductsList}
            productsList={callResult.data.data}
            setItemValues={(product) => {
              const description_en =
                product.description_en.length > 0 ? product.description_en : "";
              setValues({
                ...values,
                product_id: product.id,
                plu: product.plu,
                product_name: product.name_en,
                description_en: description_en,
                allergens_en: product.allergens_en,
              });
            }}
            setOpen={setSlideOver}
          />
        ),
        open: true,
      });
    } else {
    }
  };

  const openCurrentIngredientsList = () => {
    setSlideOver({
      childComponent: (
        <CurrentIngredientsList
          productAssemblyItems={productAssemblyItems}
          setOpen={setSlideOver}
        />
      ),
      open: true,
    });
  };

  const handleBarcodeScan = async function (scannedBarcode) {
    if (scannedBarcode || scannedBarcode !== "") {
      setBarcodeScanInProgress(true);

      const barcodeInfo = ingestBarcodeScan(scannedBarcode);

      const barcodePlu = barcodeInfo.plu;

      if (barcodePlu || barcodePlu !== "") {
        const fetchedItem = await getAssemblyItemReceivingByBarcode(
          scannedBarcode,
          0
        );

        if (fetchedItem) {
          if (values.quick_scan) {
            const newUsedWeight =
              parseFloat(fetchedItem.used_weight) +
              parseFloat(fetchedItem.in_stock);
            const newInStock = 0;
            const valuesCopy = {
              ...fetchedItem,
              used_weight: Math.round(newUsedWeight * 100) / 100,
              in_stock: Math.round(newInStock * 100) / 100,
              amount_to_use: fetchedItem.in_stock,
              is_used: true,
            };
            addAssemblyItemToProduct(valuesCopy);
          } else {
            setEmptyModelOpen({
              childComponent: (
                <AddAssemblyItemToProductForm
                  assemblyItem={fetchedItem}
                  index={0}
                  confirmAction={addAssemblyItemToProduct}
                  setOpen={setEmptyModelOpen}
                />
              ),
              open: true,
            });
          }
        } else {
          setEmptyModelOpen({
            childComponent: (
              <ModelErrorComponent
                setOpen={setEmptyModelOpen}
                subtitle="We could not find that product!"
                title="Oops"
              />
            ),
            open: true,
          });
        }
      }

      setBarcodeScanInProgress(false);
    }
  };

  const onFinishManufacturing = async function () {
    setManufactureInProgress(true);

    console.log("onFinishManufacturing ran");
    if (validate()) {
      const callResult = await finishManufacturing(values);
      console.log("finished", callResult);
      setValues({
        ...values,
        new_product_id: callResult.id,
        barcode_details: callResult.barcode_details,
        production_report: callResult.production_report,
      });
    } else {
      var subtitle = "Please complete all fields.";
      if (!values.ingredients.length > 0) {
        subtitle = "Please choose an ingredient.";
      }
      setEmptyModelOpen({
        childComponent: (
          <ModelErrorComponent
            setOpen={setEmptyModelOpen}
            subtitle={subtitle}
            title="Oops"
          />
        ),
        open: true,
      });
    }
    setManufactureInProgress(false);
  };

  const startManufacturingProcess = async function () {
    const currentDate = new Date();

    setValues({
      ...values,
      created_date: format(currentDate, "LLL dd, YYY"),
      time_start: format(currentDate, "hh:mm a"),
    });
  };

  console.log("productAssemblyItems", productAssemblyItems);
  const addAssemblyItemToProduct = (newValues) => {
    setEmptyModelOpen({
      childComponent: null,
      open: false,
    });

    const newProductAssemblyItems = [...productAssemblyItems, newValues];
    setProductAssemblyItems(newProductAssemblyItems);
    setValues({ ...values, ingredients: newProductAssemblyItems });
  };

  console.log("values", values);

  return (
    <div className="px-4 sm:px-6 lg:px-4">
      <div className="sm:flex sm:items-center bg-white px-4 py-5 border-b border-gray-200 sm:px-4 rounded-xl">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Manufacture new product
          </h1>
          <div className="flex gap-2">
            <Input
              label="Start date"
              name="created_date"
              onChange={handleInputChange}
              value={values.created_date}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-28 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              //error={errors.created_date}
            />
            <Input
              label="Time start"
              name="time_start"
              onChange={handleInputChange}
              value={values.time_start}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-28 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              //error={errors.time_start}
            />
          </div>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex gap-4">
          <button
            type="button"
            className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            onClick={() => startManufacturingProcess()}
          >
            Start production
          </button>
          <button
            type="button"
            className="block rounded-md bg-yellow-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
            onClick={() => openSearchProductsList()}
          >
            Product list
          </button>
          <button
            type="button"
            className="block rounded-md bg-rose-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
            onClick={() => openCurrentIngredientsList()}
          >
            Current ingredients
          </button>
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => onFinishManufacturing()}
          >
            {manufactureInProgress ? (
              <div className="flex">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Creating product
              </div>
            ) : (
              "Finish production"
            )}
          </button>
          <button
            type="button"
            className={classNames(
              values.new_product_id > 0 ? null : "hidden",
              "block rounded-md bg-gray-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            )}
            onClick={() =>
              setEmptyModelOpen({
                childComponent: (
                  <PrintBarcodeOrBatchModal
                    barcodeUrl={values.barcode_details.url}
                    batchUrl={values.production_report.url}
                    setOpen={setEmptyModelOpen}
                  />
                ),
                open: true,
              })
            }
          >
            Print
          </button>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-3 gap-4">
        <ManufactureNewDetails
          certifiedByOptions={certifiedByOptions}
          errors={errors}
          handleInputChange={handleInputChange}
          values={values}
        />
        <ManufactureNewDetails2
          errors={errors}
          handleInputChange={handleInputChange}
          values={values}
        />
        <ManufactureNewDetails3
          barcodeScanInProgress={barcodeScanInProgress}
          errors={errors}
          handleBarcodeScan={handleBarcodeScan}
          handleInputChange={handleInputChange}
          values={values}
        />
      </div>
      <div className="mt-5">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Product Lot/Serial
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Supplier
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Item Plu
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Item Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Used Weight
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {productAssemblyItems.length > 0 ? (
                      productAssemblyItems.map((assemblyItem, index) => (
                        <tr
                          key={assemblyItem.id}
                          className={classNames(
                            assemblyItem.in_stock === 0
                              ? "bg-red-100"
                              : assemblyItem.is_used
                              ? "bg-cyan-100"
                              : null
                          )}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {assemblyItem.product_lot}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.supplier_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.box_plu}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.product_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.amount_to_use} (kg)
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            {/* <button
                                  onClick={() => {
                                    viewProductTypeDetails(productType, index);
                                  }}
                                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  Edit
                                </button> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="py-5 text-center">
                          Please choose an ingredient
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
