import { useEffect } from "react";
import { ManufactureNewProductViewModel } from "../../viewmodels/production/ManufactureNewProductViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";
import SimpleNotification from "../../components/SimpleNotification";

import ManufactureNewProductForm from "../../components/forms/production/ManufactureNewProductForm";
import EmptyModal from "../../components/modals/EmptyModal";

function ManufactureNewProduct(props) {
  const { logout } = props;
  const {
    certifiedByOptions,
    emptyModal,
    getAssemblyItemReceivingByBarcode,
    getCertifiedByOptionsForDropdown,
    getProductsList,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    showSimpleNotification,
    slideOver,
    finishManufacturing,
  } = ManufactureNewProductViewModel(logout);

  useEffect(() => {
    getCertifiedByOptionsForDropdown();
  }, []);

  return (
    <div>
      <div className="mt-2">
        <ManufactureNewProductForm
          certifiedByOptions={certifiedByOptions}
          getAssemblyItemReceivingByBarcode={getAssemblyItemReceivingByBarcode}
          getProductsList={getProductsList}
          finishManufacturing={finishManufacturing}
          setEmptyModelOpen={setEmptyModelOpen}
          setSlideOver={setSlideOver}
        />
      </div>

      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-7xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManufactureNewProduct)
);
