import { useEffect } from "react";
import { ProductReportViewModel } from "../../viewmodels/production/ProductReportViewModel";

import { useLocation, useParams } from "react-router-dom";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";
import SimpleNotification from "../../components/SimpleNotification";

import EmptyModal from "../../components/modals/EmptyModal";
import ProductionReportForm from "../../components/forms/production/productionReport/ProductionReportForm";

function ProductionReportScreen(props) {
  const { logout } = props;

  const { productId } = useParams();
  const { pathname } = useLocation();

  console.log("pathname", pathname);

  const {
    emptyModal,
    getProductReportById,
    getProductReportByPlu,
    manufacturedProduct,
    setEmptyModelOpen,
    showSimpleNotification,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
  } = ProductReportViewModel(logout, productId !== null);

  useEffect(() => {
    if (productId) {
      console.log("calling-productId", productId);
      getProductReportById(productId);
    } else {
      getProductReportByPlu(null);
    }
  }, []);

  return (
    <div>
      <div className="mt-2">
        <ProductionReportForm
          manufacturedProduct={manufacturedProduct}
          setEmptyModelOpen={setEmptyModelOpen}
          setSlideOver={setSlideOver}
        />
      </div>

      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-7xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductionReportScreen)
);
