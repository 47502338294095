import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { SimpleNotificationType } from "../../components/SimpleNotification";

export function ManufactureNewProductViewModel(logout, isFromProductsScreen) {
  const [certifiedByOptions, setCertifiedByOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getProductsList = async function (search) {
    const payload = {
      page: 1,
      search: search,
    };
    let callResult = await makePostApiCall(`products`, payload);

    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getAssemblyItemReceivingByBarcode = async function (search, page) {
    const payload = {
      manufacture: true,
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`receivingInvoiceIndex`, payload);

    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getCertifiedByOptionsForDropdown = async function () {
    let callResult = await makePostApiCall(`certifiedByOptionsForDropdown`);

    if (callResult.success) {
      setCertifiedByOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const finishManufacturing = async function (productValues) {
    let callResult = await makePostApiCall("manufactureProduct", productValues);
    if (callResult.success) {
      console.log("finishManufacturing_call", callResult.data);
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    certifiedByOptions,
    emptyModal,
    getAssemblyItemReceivingByBarcode,
    getCertifiedByOptionsForDropdown,
    getProductsList,
    setEmptyModelOpen,
    showSimpleNotification,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    finishManufacturing,
  };
}
