import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import SideNavigationBar from "../../components/sideNavigationBar/SideNavigationBar";
import AddProduct from "../../views/setup/products/product/AddProduct";
import AssemblyItems from "../../views/setup/assemblyItems/AssemblyItems";
import Brands from "../../views/setup/brands/Brands";
import CertifiedByOptions from "../../views/setup/certifiedByOptions/CertifiedByOptions";
import Customers from "../../views/setup/customers/Customers";
import Dashboard from "../../views/dashboard/Dashboard";
import Manufacturers from "../../views/setup/manufacturers/Manufacturers";
import Preservatives from "../../views/setup/preservatives/Preservatives";
import ProductScreen from "../../views/setup/products/product/ProductScreen";
import Products from "../../views/setup/products/Products";
import Allergens from "../../views/setup/allergens/Allergens";
import Claims from "../../views/setup/claims/Claims";
import ProductTypes from "../../views/setup/productTypes/ProductTypes";
import Suppliers from "../../views/setup/suppliers/Suppliers";
import Users from "../../views/users/Users";

import ReceivingInvoiceIndex from "../../views/receiving/invoices/ReceivingInvoiceIndex";
import AllReceivingInvoices from "../../views/receiving/invoices/AllReceivingInvoices";
import AddReceivingInvoice from "../../views/receiving/invoices/AddReceivingInvoice";

import PackagingReportScreen from "../../views/packaging/NewPackageScreen";

import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { withRouter } from "../../shared/utility";
import MyAccount from "../../views/myAccount/MyAccount";

import { classNames } from "../../shared/utility";
import ManufactureNewProduct from "../../views/production/ManufactureNewProduct";
import ProductionReports from "../../views/production/ProductionReports";
import ProductionReportScreen from "../../views/production/ProductionReportScreen";

function DefaultLayout(props) {
  const { logout, userType } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  const userNavigation = [
    {
      name: "My account",
      action: () => {
        window.location = "/my-account";
      },
    },
    { name: "Sign out", action: () => logout() },
  ];

  return (
    <div className="h-full">
      <div>
        <SideNavigationBar
          isMinimized={isMinimized}
          setIsMinimized={setIsMinimized}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          userType={userType}
        />
        <div className={classNames(isMinimized ? "lg:pl-20" : "lg:pl-72")}>
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open mobile sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            <button
              type="button"
              className="hidden lg:block -m-2.5 p-2.5 text-gray-700"
              onClick={() => setIsMinimized(!isMinimized)}
            >
              <span className="sr-only">Open main sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form
                className="relative flex flex-1"
                action="#"
                method="GET"
              ></form>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open my account</span>
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        My account
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                              onClick={item.action}
                            >
                              {item.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main>
            <div className="px-4 sm:px-6 lg:px-0">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />

                <Route path="/my-account" element={<MyAccount />} />

                <Route path="/assembly-items" element={<AssemblyItems />} />

                <Route path="/brands" element={<Brands />} />

                <Route path="/customers" element={<Customers />} />

                <Route
                  path="/certification-types"
                  element={<CertifiedByOptions />}
                />

                <Route path="/manufacturers" element={<Manufacturers />} />

                <Route path="/product-allergens" element={<Allergens />} />
                <Route path="/product-claims" element={<Claims />} />
                <Route path="/product-types" element={<ProductTypes />} />
                <Route path="/my-products" element={<Products />} />
                <Route path="/my-products/new" element={<AddProduct />} />
                <Route
                  path="/my-products/:productId"
                  element={<ProductScreen />}
                />
                <Route path="/preservatives" element={<Preservatives />} />

                <Route
                  path="/receiving-index"
                  element={<ReceivingInvoiceIndex />}
                />
                <Route
                  path="/receiving-invoices"
                  element={<AllReceivingInvoices />}
                />
                <Route
                  path="/receiving-invoices/new"
                  element={<AddReceivingInvoice />}
                />

                <Route
                  path="/manufacture-product"
                  element={<ManufactureNewProduct />}
                />
                <Route
                  path="/production-reports"
                  element={<ProductionReports />}
                />
                <Route
                  path="/production-report"
                  element={<ProductionReportScreen />}
                />

                <Route
                  path="/production-reports/:productId"
                  element={<ProductionReportScreen />}
                />

                <Route
                  path="/packaging-new"
                  element={<PackagingReportScreen />}
                />

                <Route path="/suppliers" element={<Suppliers />} />
                {/* <Route
                  path="/receiving-invoices/:invoiceId"
                  element={<ReceivingInvoiceDetails />}
                /> */}

                <Route path="/users" element={<Users />} />
                {/* <Route
                  path="/subscribers/:id"
                  element={<SubscriberDetails />}
                /> */}
                <Route
                  path="*"
                  element={<Navigate to="/dashboard" replace />}
                />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)
);
