import React from "react";

import Input from "../../../controls/Input";
import SelectMenu from "../../../controls/SelectMenu";
import { convertToDefEventPara } from "../../../../shared/utility";

export default function ManufactureNewDetails3(props) {
  const {
    barcodeScanInProgress,
    errors,
    handleBarcodeScan,
    handleInputChange,
    values,
  } = props;

  return (
    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="col-span-1">
          <Input
            label="Finished Product Weight"
            name="new_weight"
            onChange={handleInputChange}
            value={values.new_weight}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.new_weight}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Finished Product Temp &deg;C"
            name="new_product_temperature"
            onChange={handleInputChange}
            value={values.new_product_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.new_product_temperature}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Allergens (EN)"
            name="allergens_en"
            onChange={handleInputChange}
            value={values.allergens_en}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            disabled={true}
            //error={errors.allergens_en}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Barcode Scanner"
            name="scanned_assembly_barcode"
            onChange={handleInputChange}
            value={values.scanned_assembly_barcode}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleBarcodeScan(e.target.value);
            }}
            disabled={barcodeScanInProgress}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.scanned_assembly_barcode}
          />
          <div className="mt-2 flex">
            <input
              id="quick_scan"
              aria-describedby="selectAllBox"
              name="quick_scan"
              type="checkbox"
              checked={values.quick_scan}
              className="mt-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onClick={(e) =>
                handleInputChange(
                  convertToDefEventPara("quick_scan", e.target.checked)
                )
              }
            />
            Quick Scan
          </div>
        </div>
      </div>
    </div>
  );
}
