import React, { useEffect } from "react";

import { useForm } from "../../useForm";

import Input from "../../../controls/Input";

const initialFValues = {
  receiving_assembly_item_id: "",
  amount_to_use: "",
  total_weight: "",
  used_weight: "",
  in_stock: "",
  pack_weight: "",
  product_cost: "",
  product_name: "",
};

export default function AddAssemblyItemToProductForm(props) {
  const { assemblyItem, confirmAction, index } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("amount_to_use" in fieldValues) {
      temp.amount_to_use = null;
      if (fieldValues.amount_to_use === "") {
        temp.amount_to_use = "Please fill out field.";
      }

      console.log("values.in_stock", values.in_stock);
      console.log("fieldValues.amount_to_use", fieldValues.amount_to_use);
      if (parseFloat(fieldValues.amount_to_use) > parseFloat(values.in_stock)) {
        temp.amount_to_use = "Not enough in stock for this amount";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    setValues({
      ...assemblyItem,
      amount_to_use: "",
      index: index,
      is_used: false,
    });
  }, [assemblyItem]);

  const onSubmit = () => {
    if (validate()) {
      const newUsedWeight =
        parseFloat(values.used_weight) + parseFloat(values.amount_to_use);
      const newInStock =
        parseFloat(values.in_stock) - parseFloat(values.amount_to_use);
      const valuesCopy = {
        ...values,
        used_weight: Math.round(newUsedWeight * 100) / 100,
        in_stock: Math.round(newInStock * 100) / 100,
        is_used: true,
      };
      confirmAction(valuesCopy);
    } else {
    }
  };

  return (
    <div className="justify-between">
      <div>
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {assemblyItem.product_name}
            </h3>
          </div>
        </div>

        {/* here */}
        <div className="pt-6 pb-5 gap-4">
          <div className="bg-white sm:rounded-xl">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Received Amount"
                  name="total_weight"
                  onChange={handleInputChange}
                  value={values.total_weight}
                  labelOn={true}
                  disabled={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.total_weight}
                />
              </div>
              <div>
                <Input
                  label="Used Weight"
                  name="used_weight"
                  onChange={handleInputChange}
                  value={values.used_weight}
                  labelOn={true}
                  disabled={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.used_weight}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="In Stock"
                  name="in_stock"
                  onChange={handleInputChange}
                  value={values.in_stock}
                  labelOn={true}
                  disabled={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.in_stock}
                />
              </div>
              <div>
                <Input
                  label="Cost"
                  name="product_cost"
                  onChange={handleInputChange}
                  value={values.product_cost}
                  labelOn={true}
                  disabled={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.product_cost}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Pack Weight"
                  name="pack_weight"
                  onChange={handleInputChange}
                  value={values.pack_weight}
                  labelOn={true}
                  disabled={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.pack_weight}
                />
              </div>
              <div>
                <Input
                  label="Amount to use"
                  name="amount_to_use"
                  onChange={handleInputChange}
                  value={values.amount_to_use}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.amount_to_use}
                />
              </div>
            </div>
          </div>

          <div className="sm:flex-no-wrap">
            <div className="my-auto flex items-center">
              <button
                onClick={() => onSubmit()}
                className="inline-flex justify-center mt-3 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
