import React, { useRef } from "react";

import Input from "../../../controls/Input";
import {
  HomeIcon,
  MagnifyingGlassIcon,
  QrCodeIcon,
  ScaleIcon,
  TagIcon,
} from "@heroicons/react/24/outline";

export default function NewPackageDetails1(props) {
  const { errors, handleBarcodeScan, handleInputChange, values } = props;

  const searchRef = useRef();

  return (
    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="col-span-2 sm:items-center px-1 pb-3 flex relative flex items-stretch flex-grow focus-within:z-10">
          <input
            type="search"
            name="searchInput"
            id="searchInput"
            className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none py-2"
            placeholder="Type or Scan Lot Number"
            ref={searchRef}
          />
          <button
            type="button"
            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
            onClick={() => handleBarcodeScan(searchRef.current.value)}
          >
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="col-span-2">
          <Input
            label="Supervisor"
            name="supervisor"
            onChange={handleInputChange}
            value={values.supervisor}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.supervisor}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Employee 1"
            name="employee_1"
            onChange={handleInputChange}
            value={values.employee_1}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.employee_1}
          />
        </div>
        <div className="col-span-2">
          <Input
            label="Packaging date"
            name="employee_1"
            onChange={handleInputChange}
            value={""}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.employee_1}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Room Temp C&deg;"
            name="production_room_temperature"
            onChange={handleInputChange}
            value={values.production_room_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Product Temp C&deg;"
            name="new_product_temperature"
            onChange={handleInputChange}
            value={values.new_product_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <button
              type="button"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex my-auto"
            >
              <QrCodeIcon className="w-6 h-6" />
              <p className="ml-2">Fixed</p>
            </button>
            <button
              type="button"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex my-auto"
            >
              <ScaleIcon className="w-6 h-6" />
              <p className="ml-2">Scale</p>
            </button>
            <button
              type="button"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex my-auto"
            >
              <HomeIcon className="w-6 h-6" />
              <p className="ml-2">Company</p>
            </button>
            <button
              type="button"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex my-auto"
            >
              <TagIcon className="w-6 h-6" />
              <p className="ml-2">Label</p>
            </button>
            <button
              type="button"
              className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 flex my-auto"
            >
              <TagIcon className="w-6 h-6" />
              <p className="ml-2">Print</p>
            </button>
            <button
              type="button"
              className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 flex my-auto"
            >
              <TagIcon className="w-6 h-6" />
              <p className="ml-2">Last label</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
