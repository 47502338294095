import React from "react";

import Input from "../../../../controls/Input";

export default function ProductReportDetailsComponent5(props) {
  const { values } = props;

  return (
    <div className="bg-gray-200 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl divide-y divide-gray-200">
      <div>
        <h1 className="text-xl px-4 py-2">Box Receiving Details</h1>
      </div>
      <div className="bg-white p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="col-span-2">
            <Input
              label="Received By"
              name="received_by"
              value={values.received_by}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Product Temperature &deg;C"
              name="product_temperature"
              value={values.product_temperature}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Truck Temperature &deg;C"
              name="truck_temperature"
              value={values.truck_temperature}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-2">
            <Input
              label="Product Condition"
              name="product_condition"
              value={values.product_condition}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Truck Condition"
              name="truck_condition"
              value={values.truck_condition}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Product Quality"
              name="product_quality"
              value={values.product_quality}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Certification"
              name="certifications"
              value={values.certifications}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Claims"
              name="claims"
              value={values.claims}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Allergens"
              name="allergens"
              value={values.allergens}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Preservatives"
              name="preservatives"
              value={values.preservatives}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
