import React from "react";

import Input from "../../../../controls/Input";
import TextArea from "../../../../controls/TextArea";

export default function ProductReportDetailsComponent2(props) {
  const { values } = props;

  return (
    <div className="bg-gray-200 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl divide-y divide-gray-200">
      <div>
        <h1 className="text-xl px-4 py-2">Inspection</h1>
      </div>
      <div className="bg-white p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="col-span-1">
            <Input
              label="Product Temperature &deg;C"
              name="raw_product_temperature"
              value={values.raw_product_temperature}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Finished Temperature &deg;C"
              name="new_product_temperature"
              value={values.new_product_temperature}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-2">
            <Input
              label="Production Room"
              name="production_room"
              value={values.production_room}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Production Room Temperature &deg;C"
              name="production_room_temperature"
              value={values.production_room_temperature}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <Input
              label="Corrective action"
              name="corrective_action"
              value={values.corrective_action}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-2">
            <Input
              label="Allergens"
              name="allergens_en"
              value={values.allergens_en}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="col-span-2">
            <Input
              label="Certification"
              name="certification"
              value={values.certification}
              labelOn={true}
              disabled={true}
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
