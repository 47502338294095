import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";
import { SimpleNotificationType } from "../../../components/SimpleNotification";

export function AddReceivingInvoiceViewModel(logout, receivingInvoiceId) {
  const [loading, setLoading] = useState(true);

  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [claimsOptions, setClaimsOptions] = useState([]);
  const [allergensOptions, setAllergensOptions] = useState([]);
  const [invoice, setInvoice] = useState({
    id: 0,
    supplier: "",
    invoice_number: "",
  });
  const [invoiceItems, setInvoiceItems] = useState([]);

  const addItemToReceivingInvoice = async function (values, invoice_id) {
    const payload = {
      ...values,
      invoice_id: invoice_id,
    };
    let callResult = await makePostApiCall(
      "addItemToReceivingInvoice",
      payload
    );
    if (callResult.success) {
      setInvoiceItems([...invoiceItems, callResult.data.receiving_item]);
      return callResult.data.receiving_item;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createReceivingInvoice = async function (values) {
    // First create the invoice, then create its sub items...
    const payload = { ...values };
    let callResult = await makePostApiCall("createReceivingInvoice", payload);

    if (callResult.success) {
      const addItemCall = await addItemToReceivingInvoice(
        values,
        callResult.data.receiving_invoice.id
      );
      setInvoice(callResult.data.receiving_invoice);
      return addItemCall;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      } else {
        setShowSimpleNotification({
          show: true,
          title: "Please fill out all fields",
          type: SimpleNotificationType.Error,
        });
      }
    }
  };

  const updateReceivingInvoice = async function (receivingInvoice, values) {
    let callResult = await makePostApiCall(
      `receivingInvoices/${receivingInvoice.id}`,
      values,
      "multipart/form-data"
    );
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "ReceivingInvoice updated",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      } else {
        setShowSimpleNotification({
          show: true,
          title: "Please fill out all fields",
          type: SimpleNotificationType.Error,
        });
      }
    }
  };

  const getAllAssemblyItems = async function () {
    let callResult = await makePostApiCall(`allAssemblyItems`);

    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getAllergensForDropdown = async function () {
    let callResult = await makePostApiCall(`allergensForDropdown`);

    if (callResult.success) {
      setAllergensOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getClaimsForDropdown = async function () {
    let callResult = await makePostApiCall(`claimsForDropdown`);

    if (callResult.success) {
      setClaimsOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getProductTypes = async function () {
    let callResult = await makePostApiCall(`productTypesForDropdown`);

    if (callResult.success) {
      setProductTypeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onGenerateBarcodePdf = async function (values) {
    const payload = { ...values };

    let callResult = await makePostApiCall("createBarcodePdf", payload);

    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      } else {
        setShowSimpleNotification({
          show: true,
          title: "Please fill out all fields",
          type: SimpleNotificationType.Error,
        });
      }
    }
  };

  const getAssemblyItemByPlu = async function (plu) {
    const payload = { plu: plu };
    let callResult = await makePostApiCall(`assemblyItems/plu/${plu}`, payload);

    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    addItemToReceivingInvoice,
    createReceivingInvoice,
    getAllAssemblyItems,
    getAllergensForDropdown,
    getAssemblyItemByPlu,
    getClaimsForDropdown,
    getProductTypes,
    invoice,
    invoiceItems,
    onGenerateBarcodePdf,
    productTypeOptions,
    claimsOptions,
    allergensOptions,
    loading,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    updateReceivingInvoice,
  };
}
