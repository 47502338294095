import * as actionTypes from "./actionTypes";

import { config } from "../../constants";

import axios from "axios";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (authData) => {
  localStorage.setItem("authToken", authData.token);
  localStorage.setItem("name", authData.user.name);
  localStorage.setItem("email", authData.user.email);
  localStorage.setItem("id", authData.user.id);
  localStorage.setItem("userType", authData.user.userType);

  return {
    type: actionTypes.AUTH_SUCCESS,
    authToken: authData.token,
    name: authData.user.name,
    email: authData.user.email,
    id: authData.user.id,
    userType: authData.user.userType,
  };
};

export const updateAccountSuccess = (user) => {
  localStorage.setItem("name", user.name);
  localStorage.setItem("email", user.email);

  return {
    type: actionTypes.UPDATE_ACCOUNT_SUCCESS,
    name: user.name,
    email: user.email,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const requestFailed = (error) => {
  return {
    type: actionTypes.REQUEST_FAILED,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("name");
  localStorage.removeItem("email");
  localStorage.removeItem("id");
  localStorage.removeItem("userType");

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationDateout) => {
  //   return (dispatch) => {
  //     setTimeout(() => {
  //       dispatch(logout());
  //     }, expirationDateout * 1000);
  //   };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const payload = {
      email: email,
      password: password,
    };
    let url = config.url.API_URL + "login";

    axios
      .post(url, payload)
      .then((response) => {
        const expirationDate = new Date(
          new Date().getTime() + response.data.expiresIn * 1000
        );

        dispatch(authSuccess(response.data));
      })
      .catch((err) => {
        dispatch(authFail(err.response));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      // if (expirationDate <= new Date()) {
      //   dispatch(logout());
      // } else {

      const authData = {
        token: localStorage.getItem("authToken"),
        user: {
          name: localStorage.getItem("name"),
          email: localStorage.getItem("email"),
          id: localStorage.getItem("id"),
          userType: localStorage.getItem("userType"),
        },
      };

      dispatch(authSuccess(authData));
      // dispatch(
      //   checkAuthTimeout(
      //     (expirationDate.getTime() - new Date().getTime()) / 1000
      //   )
      // );
      // }
    }
  };
};

export const updateAccountDetails = (payload) => {
  return (dispatch) => {
    dispatch(authStart());

    let url = config.url.API_URL + "users/" + localStorage.getItem("id");

    const headers = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      },
    };

    axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(updateAccountSuccess(payload));
      })
      .catch((err) => {
        dispatch(authFail(err.response));
      });
  };
};
