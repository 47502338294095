import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import routes from "../../shared/routes";

import { useLocation } from "react-router-dom";
import MobileNavigationBar from "./MobileNavigationBar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideNavigationBar(props) {
  const { isMinimized, setIsMinimized, sidebarOpen, setSidebarOpen, userType } =
    props;

  const location = useLocation();
  const getRoutes = routes(location.pathname, userType);
  const routesArray = getRoutes["routes"];

  return (
    <div>
      <MobileNavigationBar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        userType={userType}
      />

      {/* Static sidebar for desktop */}
      <div
        className={classNames(
          isMinimized
            ? "hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:pb-4"
            : "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col",
          "bg-indigo-600"
        )}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=white"
              alt="Your Company"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {routesArray.map((item) => (
                    <li key={item.name}>
                      {!item.children ? (
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-indigo-700 text-white"
                              : "text-white hover:text-white hover:bg-indigo-700",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <p className={isMinimized ? "hidden" : null}>
                            {item.name}
                          </p>
                        </a>
                      ) : (
                        <Disclosure as="div" defaultOpen={item.open}>
                          {/* Button click starts */}
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.current
                                    ? "bg-indigo-700 text-white"
                                    : "text-white hover:text-white hover:bg-indigo-700",
                                  "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold"
                                )}
                                onClick={() => setIsMinimized(false)}
                              >
                                <item.icon
                                  className="h-6 w-6 shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <div
                                  className={
                                    isMinimized ? "hidden" : "flex w-full"
                                  }
                                >
                                  {item.name}
                                  <ChevronRightIcon
                                    className={classNames(
                                      open
                                        ? "rotate-90 text-gray-500"
                                        : "text-gray-400",
                                      "ml-auto h-5 w-5 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                </div>
                              </Disclosure.Button>
                              <Disclosure.Panel
                                as="ul"
                                className={isMinimized ? "hidden" : "mt-1 px-2"}
                              >
                                {item.children.map((subItem) => (
                                  <li key={subItem.name}>
                                    {/* 44px */}
                                    <Disclosure.Button
                                      as="a"
                                      href={subItem.href}
                                      className={classNames(
                                        subItem.current
                                          ? "bg-indigo-700 text-white"
                                          : "text-white hover:text-white hover:bg-indigo-700",
                                        "block rounded-md py-2 pr-2 pl-9 text-sm leading-6"
                                      )}
                                    >
                                      {subItem.name}
                                    </Disclosure.Button>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                          {/* Button click ends */}
                        </Disclosure>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
