import React, { useEffect, useRef, useState } from "react";

import { useForm } from "../../useForm";

import Input from "../../../controls/Input";
import SelectMenu from "../../../controls/SelectMenu";

import { format } from "date-fns";

import { classNames, ingestBarcodeScan } from "../../../../shared/utility";
import AssemblyItemsTableComponent from "../../../../viewmodels/receiving/invoices/AssemblyItemsTableComponent";
import DatePickerInput from "../../../controls/DatePickerInput";

import { config } from "../../../../constants";

const initialFValues = {
  invoice_number: "",
  date: "",
  product_id: "",
  isBarcode: { value: 0, label: "" },
  received_by: "",
  manufacturer_id: "",
  supplier_id: "",
  product_code: "",
  product_name: "",
  product_lot: "",
  pack_weight: "",
  total_weight: "",
  product_cost: "",
  total_cost: "",
  box_plu: "",
  box_barcode: "",
  box_lot: "",
  box_date: "",
  note: "",
  certification_type: 0,
  allergens: "",
  preservatives: "",
  product_condition: "",
  product_type: 0,
  is_non_meat: 0,
  product_claims: 0,
  product_temperature: 0,
  product_quality: "",
  truck_temperature: 0,
  truck_condition: 0,
  pallet_condition: 0,
};

const barcodeOptions = [
  { value: 1, label: "No Barcode" },
  { value: 2, label: "Barcode" },
];

const conditionTypes = [
  { value: 0, label: "Choose Condition" },
  { value: 1, label: "Satisfactory" },
  { value: 2, label: "NOT Satisfactory" },
];

export default function AddReceivingInvoiceForm(props) {
  const {
    addItemToReceivingInvoice,
    createReceivingInvoice,
    getAllAssemblyItems,
    getAssemblyItemByPlu,
    invoice,
    invoiceItems,
    onGenerateBarcodePdf,
    receivingInvoice,
    isEdit,
    setSlideOver,
  } = props;

  const [barcodeScanInProgress, setBarcodeScanInProgress] = useState(false);
  const [createBarcodeInProgress, setCreateBarcodeInProgress] = useState(false);
  const [createInvoiceInProgress, setCreateInvoiceInProgress] = useState(false);

  const barcodeInputRef = useRef(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.supplier_id = null;
    if (values.supplier_id < 1) {
      temp.supplier_id = "Please choose an item.";
    }

    if ("isBarcode" in fieldValues) {
      temp.isBarcode = null;
      if (fieldValues.isBarcode.value < 1) {
        temp.isBarcode = "Please fill out field.";
      }
    }

    if ("total_weight" in fieldValues) {
      temp.total_weight = null;
      if (fieldValues.total_weight === "" && values.isBarcode.value === 1) {
        temp.total_weight = "Please fill out field.";
      }
    }

    if ("invoice_number" in fieldValues) {
      temp.invoice_number = null;
      if (fieldValues.invoice_number === "") {
        temp.invoice_number = "Please fill out field.";
      }
    }

    if ("received_by" in fieldValues) {
      temp.received_by = null;
      if (fieldValues.received_by === "") {
        temp.received_by = "Please fill out field.";
      }
    }

    if ("product_lot" in fieldValues) {
      temp.product_lot = null;
      if (
        fieldValues.product_lot === "" ||
        (values.isBarcode.value === 1 && fieldValues.product_lot.length !== 6)
      ) {
        temp.product_lot = "Please fill out field.";
      }
    }

    if (
      "product_temperature" in fieldValues ||
      fieldValues.product_temperature < 1
    ) {
      temp.product_temperature = null;
      if (fieldValues.product_temperature === "") {
        temp.product_temperature = "Please fill out field.";
      }
    }

    if (
      "product_condition" in fieldValues ||
      fieldValues.product_condition === 0
    ) {
      temp.product_condition = null;
      if (fieldValues.product_condition === "") {
        temp.product_condition = "Please fill out field.";
      }
    }

    if ("product_quality" in fieldValues || fieldValues.product_quality === 0) {
      temp.product_quality = null;
      if (fieldValues.product_quality === "") {
        temp.product_quality = "Please fill out field.";
      }
    }

    if ("truck_temperature" in fieldValues) {
      temp.truck_temperature = null;
      if (fieldValues.truck_temperature === "") {
        temp.truck_temperature = "Please fill out field.";
      }
    }

    if ("truck_condition" in fieldValues) {
      temp.truck_condition = null;
      if (
        fieldValues.truck_condition === "" ||
        fieldValues.truck_condition === 0
      ) {
        temp.truck_condition = "Please fill out field.";
      }
    }

    if ("pallet_condition" in fieldValues) {
      temp.pallet_condition = null;
      if (
        fieldValues.pallet_condition === "" ||
        fieldValues.pallet_condition === 0
      ) {
        temp.pallet_condition = "Please fill out field.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    if (isEdit && receivingInvoice) {
      setValues(receivingInvoice);
    } else {
      setValues({ ...values, date: format(new Date(), "LLL dd, YYY") });
    }
  }, [isEdit, receivingInvoice, setValues]);

  const openSearchAssemblyItems = async function () {
    let callResult = await getAllAssemblyItems();
    if (callResult.success) {
      setSlideOver({
        childComponent: (
          <AssemblyItemsTableComponent
            assemblyItemsList={callResult.data}
            setItemValues={(assemblyItem) => {
              setValues({
                ...values,
                product_id: assemblyItem.id,
                supplier_id: assemblyItem.supplier_id,
                supplier_name: assemblyItem.supplier_name,
                manufacturer_id: assemblyItem.manufacturer_id,
                manufacturer_name: assemblyItem.manufacturer_name,
                pack_weight: assemblyItem.pack_weight,
                product_name: assemblyItem.product_name,
                product_code: assemblyItem.product_code,
                product_cost: assemblyItem.cost,
                certification_type: assemblyItem.certification_type,
                product_type: assemblyItem.product_type,
                is_non_meat: assemblyItem.is_non_meat,
                total_cost: values.total_weight * assemblyItem.cost,
              });
            }}
            setOpen={setSlideOver}
          />
        ),
        open: true,
      });
    } else {
    }
  };

  const handleBarcodeScan = async function (scannedBarcode) {
    if (scannedBarcode || scannedBarcode !== "") {
      setBarcodeScanInProgress(true);

      const barcodeInfo = ingestBarcodeScan(scannedBarcode);

      const barcodePlu = barcodeInfo.plu;

      console.log("barcodeInfo", barcodeInfo);
      if (barcodePlu || barcodePlu !== "") {
        let callResult = await getAssemblyItemByPlu(barcodePlu);

        const valsss = {
          ...values,
          box_plu: barcodePlu,
          box_barcode: scannedBarcode,
          box_lot: barcodeInfo.serial,
          box_date: barcodeInfo.date,
          pack_weight: callResult.pack_weight,
          total_weight: barcodeInfo.weight,
          certification_type: callResult.certification_type["value"],
          manufacturer_id: callResult.manufacturer["value"],
          manufacturer_name: callResult.manufacturer["label"],
          product_code: barcodePlu,
          product_cost: callResult.cost,
          product_lot: barcodeInfo.serial,
          product_name: callResult.product_name,
          product_id: callResult.id,
          product_type: callResult.product_type["value"],
          is_non_meat: callResult.is_non_meat["value"],
          supplier_id: callResult.supplier["value"],
          supplier_name: callResult.supplier["label"],
          total_cost: callResult.cost * barcodeInfo.weight,
        };
        setValues(valsss);

        onCreateInvoice(valsss);
        // if (barcodeInputRef) {
        //   barcodeInputRef.current.focus();
        // }
      }
      setBarcodeScanInProgress(false);
    }
  };

  const onCreateInvoice = async function (newValues) {
    if (validate()) {
      setCreateInvoiceInProgress(true);
      var callResult = null;
      if (invoice.id > 0) {
        callResult = await addItemToReceivingInvoice(newValues, invoice.id);

        setValues({ ...values, scanned_box_label: "" });
      } else {
        callResult = await createReceivingInvoice(values);
        setValues({
          ...values,
          receiving_item_id: callResult.id,
          scanned_box_label: "",
        });
      }

      setCreateInvoiceInProgress(false);
    } else {
    }
  };

  const generateBarcodeStringClicked = async function () {
    if (values.box_barcode) {
      window
        .open(config.url.STORAGE_URL + "/" + values.barcode_url, "_blank")
        .focus();
    } else {
      if (validate()) {
        setCreateBarcodeInProgress(true);
        const newBarcode = await onGenerateBarcodePdf(values);
        setCreateBarcodeInProgress(false);
        setValues({
          ...values,
          box_barcode: newBarcode.barcode,
          barcode_url: newBarcode.barcode_url,
        });
        window
          .open(config.url.STORAGE_URL + "/" + newBarcode.barcode_url, "_blank")
          .focus();
      }
    }
  };

  return (
    <div className="justify-between">
      <div className={classNames(isEdit ? null : "divide-y divide-gray-500")}>
        <div
          className={classNames(
            isEdit ? "hidden" : "",
            "pt-6 pb-5 flex items-center justify-between flex-wrap sm:flex-nowrap"
          )}
        >
          <div className="mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Add new receiving invoice
            </h3>
          </div>
        </div>

        {/* here */}
        <div className="pt-6 pb-5 grid grid-cols-2 gap-4">
          <div id="formGrid" className="px-3">
            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl p-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <SelectMenu
                    handleInputChange={(e) => {
                      setValues({
                        ...values,
                        isBarcode: e.target.value,
                        receiving_item_id: "",
                        allergens: "",
                        box_barcode: "",
                        barcode_url: "",
                        box_date: "",
                        box_lot: "",
                        box_plu: "",
                        certification_type: "",
                        manufacturer_id: "",
                        manufacturer_name: "",
                        pack_weight: "",
                        total_weight: "",
                        preservatives: "",
                        product_claims: "",
                        product_code: "",
                        product_condition: "",
                        product_temperature: "",
                        product_cost: "",
                        product_quality: "",
                        product_id: "",
                        product_lot: "",
                        product_name: "",
                        product_type: "",
                        is_non_meat: "",
                        supplier_id: "",
                        supplier_name: "",
                        total_cost: "",
                      });
                    }}
                    name="isBarcode"
                    options={barcodeOptions}
                    title={"Barcode"}
                    error={errors.isBarcode}
                  />
                </div>
                <div>
                  <Input
                    label="Invoice"
                    name="invoice_number"
                    onChange={handleInputChange}
                    value={values.invoice_number}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    error={errors.invoice_number}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4">
                <div>
                  <Input
                    label="Received by"
                    name="received_by"
                    onChange={handleInputChange}
                    value={values.received_by}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    error={errors.received_by}
                  />
                </div>
                <div>
                  <DatePickerInput
                    label="Date"
                    name="date"
                    onChange={handleInputChange}
                    value={values.date}
                    labelOn={true}
                    type="text"
                    dateFormat={"LLL dd, YYY"}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-3 gap-4">
                <div>
                  <Input
                    label="Product name"
                    name="product_name"
                    onChange={handleInputChange}
                    value={values.product_name}
                    labelOn={true}
                    type="text"
                    disabled={true}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
                <div>
                  <Input
                    label="Manufacturer"
                    name="manufacturer_name"
                    onChange={handleInputChange}
                    value={values.manufacturer_name}
                    labelOn={true}
                    disabled={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
                <div
                  className={classNames(
                    values.isBarcode.value === 2 ? "hidden" : null
                  )}
                >
                  <Input
                    label="Product lot"
                    name="product_lot"
                    onChange={handleInputChange}
                    value={values.product_lot}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    error={errors.product_lot}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-3 gap-4">
                <div>
                  <Input
                    label="Product Temperature"
                    name="product_temperature"
                    onChange={handleInputChange}
                    value={values.product_temperature}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    error={errors.product_temperature}
                  />
                </div>
                <div>
                  <SelectMenu
                    handleInputChange={handleInputChange}
                    name="product_condition"
                    options={conditionTypes}
                    title="Product Condition"
                    error={errors.product_condition}
                  />
                </div>
                <div>
                  <SelectMenu
                    handleInputChange={handleInputChange}
                    name="product_quality"
                    options={conditionTypes}
                    title="Product quality"
                    error={errors.product_quality}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4">
                <div
                  className={classNames(
                    values.isBarcode.value === 2 ? "hidden" : null
                  )}
                >
                  <Input
                    label="Total weight"
                    name="total_weight"
                    onChange={handleInputChange}
                    value={values.total_weight}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4">
                <div>
                  <Input
                    label="Truck Temperature"
                    name="truck_temperature"
                    onChange={handleInputChange}
                    value={values.truck_temperature}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    error={errors.truck_temperature}
                  />
                </div>
                <div>
                  <SelectMenu
                    handleInputChange={handleInputChange}
                    name="truck_condition"
                    options={conditionTypes}
                    title="Truck Condition"
                    error={errors.truck_condition}
                  />
                </div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4">
                <div>
                  <SelectMenu
                    handleInputChange={handleInputChange}
                    name="pallet_condition"
                    options={conditionTypes}
                    title="Pallet Condition"
                    error={errors.pallet_condition}
                  />
                </div>
              </div>
            </div>

            <div className="sm:flex-no-wrap">
              <div className="my-auto flex items-center">
                <button
                  onClick={() => onCreateInvoice()}
                  className="inline-flex justify-center mt-3 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 items-center"
                >
                  {createInvoiceInProgress ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : invoice.id > 0 ? (
                    "Add to receiving"
                  ) : (
                    "Create receiving"
                  )}
                </button>
                <button
                  onClick={() => generateBarcodeStringClicked(values)}
                  className={classNames(
                    invoice.id > 0 &&
                      values.isBarcode.value === 1 &&
                      values.product_id > 0
                      ? "inline-flex justify-center mt-3 ml-5 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
                      : "hidden"
                  )}
                >
                  {createBarcodeInProgress ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Print barcode"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div id="assemblyItems">
            <div
              id="buttons"
              className={classNames(
                values.isBarcode.value === 1 ? null : "hidden",
                "my-auto flex items-center"
              )}
            >
              <button
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 items-center"
                onClick={() => {
                  openSearchAssemblyItems();
                }}
              >
                Search assembly
              </button>
            </div>
            {values.isBarcode.value === 2 ? (
              <div id="barcode-scan-text-input-parent">
                <div>
                  <Input
                    label="Scan box label"
                    name="scanned_box_label"
                    disabled={barcodeScanInProgress}
                    ref={barcodeInputRef}
                    onChange={handleInputChange}
                    value={values.scanned_box_label}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleBarcodeScan(e.target.value);
                    }}
                    labelOn={true}
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
              </div>
            ) : null}
            {invoiceItems.length > 0 ? (
              <div id="barcode-scanned-items-parent">
                <div
                  id="product-table"
                  className="mt-3 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
                >
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Product Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Serial/Lot
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Total weight
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {invoiceItems.map((invoiceItem) => (
                        <tr key={invoiceItem.id} className="cursor-pointer">
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                            {invoiceItem.product_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {invoiceItem.product_lot}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {invoiceItem.total_weight} (kg)
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
