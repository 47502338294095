import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";
import PreservativesForm from "../../../components/forms/setup/preservatives/PreservativesForm";
import { SimpleNotificationType } from "../../../components/SimpleNotification";

export function PreservativesViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [preservatives, setPreservatives] = useState({ data: [] });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const user_id = localStorage.getItem("id");

  const getPreservatives = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`preservatives`, payload);
    setLoading(false);
    if (callResult.success) {
      setPreservatives(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createPreservative = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall("createPreservative", payload);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Preservative added",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getPreservatives("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updatePreservative = async function (preservative, values, index) {
    let callResult = await makePutApiCall(
      `preservative/${preservative.id}`,
      values
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Preservative updated",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getPreservatives("", 1);
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddPreservativeSlideOver = () => {
    setSlideOver({
      childComponent: (
        <PreservativesForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createPreservative}
        />
      ),
      open: true,
    });
  };

  const viewPreservativeDetails = async function (preservative, index) {
    setSlideOver({
      childComponent: (
        <PreservativesForm
          preservative={preservative}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={updatePreservative}
        />
      ),
      open: true,
    });
  };

  return {
    getPreservatives,
    loading,
    preservatives,
    openAddPreservativeSlideOver,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    viewPreservativeDetails,
  };
}
