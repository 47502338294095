import React from "react";

import Input from "../../../../components/controls/Input";

export default function InvoiceRecordProductDetails(props) {
  const { invoiceDetails } = props;

  return (
    <div>
      <div className="mt-5 px-4 py-5 sm:p-2 pt-0 grid grid-cols-1 overflow-hidden rounded-lg bg-white shadow md:grid-cols-2">
        <div className="col-span-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Assembly item details
          </h3>
        </div>
        <div className="mt-2 col-span-1">
          <Input
            label="Supplier"
            name="supplier_name"
            value={invoiceDetails.supplier_name}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Product"
            name="product_name"
            value={invoiceDetails.product_name}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-1">
          <Input
            label="Total Weight"
            name="total_weight"
            value={invoiceDetails.total_weight}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Pack Weight"
            name="pack_weight"
            value={invoiceDetails.pack_weight}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-1">
          <Input
            label="Box Plu"
            name="pack_weight"
            value={invoiceDetails.product_code}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 ml-2 col-span-1">
          <Input
            label="Product Code"
            name="pack_weight"
            value={invoiceDetails.product_code}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-2">
          <Input
            label="Box Lot"
            name="pack_weight"
            value={invoiceDetails.product_lot}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <div className="mt-2 col-span-2">
          <Input
            label="Box Barcode"
            name="pack_weight"
            value={invoiceDetails.box_barcode}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
      </div>
    </div>
  );
}
